import React from "react";
import style from "./OneTwoBox.module.scss";
import PropTypes from "prop-types";
import VBox from "../VBox";
import HBox from "../HBox";
import MiscUtil from "../../../../utils/MiscUtil";

const OneTwoBox = function(props){
    const cn = style.parent + ((props.className) ? " " + props.className : "");
    const kidsCls = style.scoped_wrapper + ((props.childrenWrapperClass) ? " " + props.childrenWrapperClass : "");
    let svgWrapper = null
    if(props.svg) {
        svgWrapper = <VBox className={style.svg_wrapper}>{props.svg}</VBox>;
    }
    const ranNum = MiscUtil.getRandomNumber(1,5);
    let h1Cls = style.a;
    if(ranNum === 2)
        h1Cls = style.l;
    else if(ranNum === 3)
        h1Cls = style.s;
    else if(ranNum === 4)
        h1Cls = style.w;

    return (
      <VBox className={cn}>
          <HBox className={style.one_wrapper}>
              <HBox className={style.scoped_wrapper}>
                  <VBox className={style.text_wrapper}>
                      <h1 className={h1Cls}>{props.title}</h1>
                      <p>{props.description}</p>
                  </VBox>
                  {svgWrapper}
              </HBox>
          </HBox>
          <VBox className={style.two_wrapper}>
              <VBox className={kidsCls}>
                  {props.children}
              </VBox>
          </VBox>
      </VBox>
    )
};

OneTwoBox.propTypes = {
    children: PropTypes.node.isRequired,
};

export default OneTwoBox;
