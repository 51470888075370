import React from "react"

import style from "./PricingOption.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox";
import LinkButton from "../../../bosom/components/elements/buttons/link-button/LinkButton";

const PricingOption = (props) => {

    let pricing = null;

    if(props.monthlyPrice){
        pricing = <div className={style.pricing_wrapper}>
            <label><strong>{props.monthlyPrice}</strong> per month</label>
            <em>or</em>
            <label><strong>{props.yearlyPrice}</strong> per year</label>
        </div>
    }

    return (
        <VBox className={style.parent}>
            <div className={style.svg_wrapper}>
                {props.svg}
            </div>
            <VBox className={style.content_wrapper}>
                <h3>{props.title}</h3>
                <h4>{props.subTitle}</h4>
                <p>{props.description}</p>
                {pricing}
                <LinkButton label={props.linkLabel} to={props.linkUrl} className={style.lnk_btn}/>
            </VBox>
        </VBox>
    )
}
export default PricingOption;
