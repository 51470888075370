import React from "react"

import style from "./PricingOptions.module.scss";
import VBox from "../../../bosom/components/layout/boxes/VBox";
import HBox from "../../../bosom/components/layout/boxes/HBox";
import PricingOption from "./PricingOption";
import PricingPalsSubscribeSvg from "../assets/PricingPalsSubscribeSvg";
import PricingCaregiverSubscribeSvg from "../assets/PricingCaregiverSubscribeSvg";
import PricingBulkSubscribeSvg from "../assets/PricingBulkSubscribeSvg";

const PricingOptions = () => {

    return (
        <VBox className={style.parent}>
            <HBox className={style.content_wrapper}>
                <PricingOption title={"PALS"}
                               subTitle={"Single Account"}
                               svg={<PricingPalsSubscribeSvg/>}
                               description={"Looking for the account for yourself? A single account with access to all features is right for you."}
                               linkLabel={"Subscribe"}
                               linkUrl={"/subscribe/pals"}
                               monthlyPrice={"0.00"}
                               yearlyPrice={"0.00"}/>
                <PricingOption title={"Care Giver"}
                               subTitle={"Two Accounts"}
                               svg={<PricingCaregiverSubscribeSvg/>}
                               description={"Helping someone else? A Care Giver subscription gives you two accounts at a discounted price."}
                               linkLabel={"Subscribe"}
                               linkUrl={"/subscribe/caregiver"}
                               monthlyPrice={"0.00"}
                               yearlyPrice={"0.00"}/>
                <PricingOption title={"Bulk Access"}
                               subTitle={"Multiple Accounts"}
                               svg={<PricingBulkSubscribeSvg/>}
                               description={"Interested in bulk accounts? Contact us and we will find the best pricing model for your use case."}
                               linkLabel={"Contact US"}
                               linkUrl={"/contact/bulk"}/>
            </HBox>
        </VBox>
    )
}
export default PricingOptions;
