import React, {Component} from "react"

import style from "./LinkButton.module.scss";
import PropTypes from "prop-types"
import {Link} from "gatsby";

class LinkButton extends Component {
    render() {
        const cls = style.link_btn_parent + ((this.props.className) ? " " + this.props.className : "");
        return (
            <Link to={this.props.to} className={cls}>
                <h2 className={style.link_label}>
                    {this.props.label}
                </h2>
            </Link>
        )
    }
}

LinkButton.propTypes = {
    label: PropTypes.node.isRequired,
    to: PropTypes.node.isRequired
};

export default LinkButton;
