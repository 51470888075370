import React from "react"
import style from "./../../../assets/scss/_svgs.module.scss";

const width = '100%';
const height = '100%';


const PricingSvg = (props) => (
    <svg viewBox="0 0 1023.62274 795.00067"
         height={height} width={width}
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         fillRule="evenodd" clipRule="evenodd"
         shapeRendering="geometricPrecision">

        <circle cx="541.0001" cy="373.0001" r="373.0001" fill="#f2f2f2"/>
        <path
            d="M1000.18863,799.50034c0,15.464-204.15815,48-456,48s-456-32.536-456-48,204.15815-8,456-8S1000.18863,784.03636,1000.18863,799.50034Z"
            transform="translate(-88.18863 -52.49966)" fill="#3f3d56"/>
        <path
            d="M1000.18863,799.50034c0,15.464-204.15815,48-456,48s-456-32.536-456-48,204.15815-8,456-8S1000.18863,784.03636,1000.18863,799.50034Z"
            transform="translate(-88.18863 -52.49966)" opacity="0.1"/>
        <ellipse cx="456" cy="747.00067" rx="456" ry="28" fill="#3f3d56"/>
        <ellipse cx="459" cy="737.00067" rx="184" ry="17" opacity="0.1"/>
        <path d="M827.18863,667.41248c0,63.20412,39.65095,114.3481,88.6519,114.3481"
              transform="translate(-88.18863 -52.49966)" fill="#3f3d56"/>
        <path d="M915.84053,781.76058c0-63.91429,44.24816-115.63291,98.93038-115.63291"
              transform="translate(-88.18863 -52.49966)" className={style.s_fill}/>
        <path d="M859.30888,673.142c0,60.0372,25.28467,108.61854,56.53165,108.61854"
              transform="translate(-88.18863 -52.49966)" className={style.s_fill}/>
        <path d="M915.84053,781.76058c0-81.66825,51.144-147.75317,114.3481-147.75317"
              transform="translate(-88.18863 -52.49966)" fill="#3f3d56"/>
        <path
            d="M897.19045,782.56675s12.5723-.38722,16.36128-3.0853,19.33936-5.91973,20.27931-1.5926,18.89383,21.52115,4.69977,21.63564-32.98069-2.21094-36.76237-4.51451S897.19045,782.56675,897.19045,782.56675Z"
            transform="translate(-88.18863 -52.49966)" fill="#a8a8a8"/>
        <path
            d="M938.78411,798.0182c-14.19406.11453-32.98067-2.21092-36.76236-4.5145-2.88-1.7543-4.02759-8.04913-4.41158-10.95332-.26592.01143-.41971.01636-.41971.01636s.7963,10.13965,4.578,12.44323,22.5683,4.629,36.76236,4.5145c4.09727-.033,5.51253-1.49079,5.43482-3.64984C943.39643,797.17908,941.83377,797.99361,938.78411,798.0182Z"
            transform="translate(-88.18863 -52.49966)" opacity="0.2"/>
        <rect x="355" y="612.00067" width="226" height="132" fill="#3f3d56"/>
        <path
            d="M716.10713,665.62673H549.48662a20.60194,20.60194,0,0,1-20.60194-20.60194V568.68753a8.16181,8.16181,0,0,1,2.54685-5.92346l88.29238-83.69382a19.00265,19.00265,0,0,1,26.14592,0l84.18211,79.79762a21.33384,21.33384,0,0,1,6.65713,15.48311v70.67381A20.60194,20.60194,0,0,1,716.10713,665.62673Z"
            transform="translate(-88.18863 -52.49966)" fill="#2f2e41"/>
        <path
            d="M440.696,517.44126H646.78857a0,0,0,0,1,0,0v66.95353a28.73228,28.73228,0,0,1-28.73228,28.73228h-148.628A28.73228,28.73228,0,0,1,440.696,584.39479V517.44126A0,0,0,0,1,440.696,517.44126Z"
            opacity="0.1"/>
        <path d="M528.66819,564.96179" transform="translate(-88.18863 -52.49966)" fill="none" stroke="#3f3d56"
              strokeMiterlimit="10"/>
        <rect x="467.75651" y="451.84668" width="153.27049" height="153.27049" rx="11.33557" className={style.l_fill}/>
        <polygon
            points="621.027 520.883 621.027 605.117 467.757 605.117 467.757 520.883 544.392 557.924 621.027 520.883"
            opacity="0.1"/>
        <path
            d="M632.58039,615.186,534.386,567.72542a3.9841,3.9841,0,0,0-5.71785,3.58708v81.65761a12.44014,12.44014,0,0,0,12.44015,12.44014h182.9441a12.44015,12.44015,0,0,0,12.44015-12.44014V572.71135a4.86167,4.86167,0,0,0-6.97731-4.37719Z"
            transform="translate(-88.18863 -52.49966)" fill="#2f2e41"/>
        <rect x="480.96202" y="465.91813" width="42.86378" height="6.06154" fill="#f2f2f2"/>
        <rect x="477.49828" y="487.99947" width="127.29244" height="3.46374" fill="#f2f2f2"/>
        <rect x="477.49828" y="499.68959" width="127.29244" height="3.46374" fill="#f2f2f2"/>
        <rect x="477.49828" y="511.37971" width="127.29244" height="3.46374" fill="#f2f2f2"/>
        <ellipse cx="474.03454" cy="454.26029" rx="17.75167" ry="17.78395" fill="#fff"/>
        <path
            d="M562.22317,485.94523a21.21541,21.21541,0,1,0,21.21541,21.2154A21.27777,21.27777,0,0,0,562.22317,485.94523Zm-4.35528,32.52689L546.995,507.59922l3.04971-3.04971,7.83338,7.83338,16.53374-16.53374,3.04972,3.04971-19.59366,19.57326Z"
            transform="translate(-88.18863 -52.49966)" fill="#57b894"/>
        <rect x="563.62274" y="111.0084" width="460" height="192" fill="#3f3d56"/>
        <path
            d="M837.64209,311.50806h-52a181.87191,181.87191,0,0,1,0-62h52A111.55883,111.55883,0,0,0,837.64209,311.50806Z"
            transform="translate(-88.18863 -52.49966)" className={style.gray_fill}/>
        <path
            d="M982.97281,311.50806h-52a181.87191,181.87191,0,0,1,0-62h52A111.55883,111.55883,0,0,0,982.97281,311.50806Z"
            transform="translate(-88.18863 -52.49966)" className={style.gray_fill}/>
        <path
            d="M763.81137,269.50806h-52a181.87242,181.87242,0,0,1,0-62h52A111.55883,111.55883,0,0,0,763.81137,269.50806Z"
            transform="translate(-88.18863 -52.49966)" className={style.a_fill}/>
        <path d="M856.81137,269.50806h52a181.87242,181.87242,0,0,0,0-62h-52A111.559,111.559,0,0,1,856.81137,269.50806Z"
              transform="translate(-88.18863 -52.49966)" className={style.l_fill}/>
        <path d="M999.81137,269.50806h52a181.87242,181.87242,0,0,0,0-62h-52A111.559,111.559,0,0,1,999.81137,269.50806Z"
              transform="translate(-88.18863 -52.49966)" className={style.s_fill}/>
        <path
            d="M342.06811,214.4298a7.4308,7.4308,0,0,0-4.30988.96279,11.24267,11.24267,0,0,0-2.91528,3.84791,46.02889,46.02889,0,0,1-12.53915,14.164c-3.72584,2.7295-8.46268,6.13278-7.82522,11.16522a15.09068,15.09068,0,0,0,1.8161,4.71317c3.43712,6.93836,12.81653,12.30025,11.89395,20.2175,3.41493-5.71689-1.15277-8.54368,2.26216-14.26057,1.62626-2.7225,4.45136-5.79574,6.90669-4.086.82212.57248,1.36193,1.59925,2.21793,2.10155,2.04252,1.19858,4.21547-1.0928,6.0235-2.71586,6.23458-5.59676,15.10074-4.13054,22.859-2.38672,3.66268.82325,7.68572,1.90334,9.85089,5.404,2.84674,4.60259-2.70467,9.57344-4.10732,14.77713a2.83544,2.83544,0,0,0,3.043,3.52881c2.32792-.22964,5.08559-.41785,5.22335-1.43939,2.92888.10427,6.51764-.22744,7.77256-3.2772a12.824,12.824,0,0,0,.6016-3.72952c.46059-5.12293,2.63487-9.79658,4.07527-14.6724s2.06671-10.59-.36407-14.91292a17.25553,17.25553,0,0,0-3.18466-3.87059C377.57435,216.61144,359.438,214.41,342.06811,214.4298Z"
            transform="translate(-88.18863 -52.49966)" fill="#2f2e41"/>
        <path
            d="M331.18863,279.50034s3,25-8,27,10,36,10,36l50,6-12-40s-8-3-3-23S331.18863,279.50034,331.18863,279.50034Z"
            transform="translate(-88.18863 -52.49966)" fill="#a0616a"/>
        <polygon points="212.5 656.501 216.5 711.501 237.5 711.501 231.5 656.501 212.5 656.501" fill="#a0616a"/>
        <polygon points="366.5 552.501 369.5 598.501 392.5 588.501 383.5 550.501 366.5 552.501" fill="#a0616a"/>
        <path
            d="M285.68863,499.00034s1,58,7,77,5,20,4,23-2,1-2,6-2,84,0,91-6,19,0,20,34,0,35-6-8-8-4-12,10-86,10-86l14-57,26-30h55l17,66s-7,19-2,19,35,6,35-16-10-87-12-89,1-9-2-12-38-24-51-28-31.23672-8.7608-31.23672-8.7608Z"
            transform="translate(-88.18863 -52.49966)" fill="#2f2e41"/>
        <path
            d="M480.68863,633.00034s-15-5-22,10-4,19-4,19,23,8,27,4c1.74031-1.74031,7.26648-2.34487,12.95479-2.47252,8.66352-.19443,10.96851-12.45138,2.78636-15.30562q-.36612-.12771-.74115-.22186C488.68863,646.00034,480.68863,633.00034,480.68863,633.00034Z"
            transform="translate(-88.18863 -52.49966)" fill="#2f2e41"/>
        <circle cx="265.5" cy="213.50067" r="30" fill="#a0616a"/>
        <path
            d="M314.68863,319.00034s49,10,61-6,17,45,17,45l6,64-9,35s-47,38-62,41-38,5-38,5,7-111,7-113S314.68863,319.00034,314.68863,319.00034Z"
            transform="translate(-88.18863 -52.49966)" className={style.s_fill}/>
        <path
            d="M329.59072,300.24567s-10.90209-6.24533-13.90209-.24533-31,21-34,21,6,83,2,93-23,83-12,89,3-6,14,9,65,15,68,9-24-51-19-93,13-100,6-108S329.59072,300.24567,329.59072,300.24567Z"
            transform="translate(-88.18863 -52.49966)" fill="#575a89"/>
        <path
            d="M365.68863,307.00034l1.3929-4.79354s41.6071,13.79354,43.6071,22.79354,1,72-6,77-17,13-10,25,15,25,21,26,17,8,14,15-39-6-39-6-24-18-23-46S365.68863,307.00034,365.68863,307.00034Z"
            transform="translate(-88.18863 -52.49966)" fill="#575a89"/>
        <path d="M400.68863,425.00034l-24,45s-35,36-15,40,31-33,31-33l26-37Z" transform="translate(-88.18863 -52.49966)"
              fill="#a0616a"/>
        <path
            d="M343.52954,222.27923a6.809,6.809,0,0,0-3.50843.68049,8.17745,8.17745,0,0,0-2.37315,2.71968,34.55082,34.55082,0,0,1-10.2074,10.011c-3.033,1.92919-6.889,4.33461-6.37,7.89151a9.86807,9.86807,0,0,0,1.47838,3.33123,26.20321,26.20321,0,0,1,3.184,16.33228l8.33968-12.122c1.32385-1.92425,3.6236-4.09639,5.62234-2.88795.66923.40462,1.10866,1.13034,1.80548,1.48536,1.6627.84714,3.43157-.77239,4.90339-1.91956,5.0752-3.95575,12.29263-2.91943,18.60821-1.68691,2.98157.58187,6.25649,1.34526,8.019,3.81949,2.929,4.11169-.12942,10.05277,1.58,14.80281a4.36387,4.36387,0,0,0,1.80562-2.88165c2.38423.0737,5.30563-.16075,6.32719-2.3163a7.98136,7.98136,0,0,0,.48972-2.636c.375-3.62085,2.1449-6.92416,3.31745-10.37036s1.68238-7.48491-.29637-10.54035a12.7536,12.7536,0,0,0-2.59245-2.7357C372.4331,223.82119,357.66933,222.26522,343.52954,222.27923Z"
            transform="translate(-88.18863 -52.49966)" fill="#2f2e41"/>
        <path d="M400.68863,321.00034l9.31712,2.47308s21.68288,55.52692,16.68288,93.52692-8,30-8,30-8-18-26-14Z"
              transform="translate(-88.18863 -52.49966)" fill="#575a89"/>
        <path
            d="M302.07409,756.73158c-2.41147,3.277-1.928,7.96135-4.06406,11.42416-1.8516,3.00161-5.34113,4.57544-7.65712,7.23519a19.39462,19.39462,0,0,0-1.96864,2.83187c-2.14841,3.59866-3.89467,8.21079-1.77877,11.82867,1.70211,2.91036,5.27988,4.04783,8.5611,4.82308,4.14726.97987,8.4563,1.76409,12.65145,1.01531s8.29512-3.31759,9.79091-7.3079a28.2303,28.2303,0,0,1,1.04688-2.95421c2.27381-4.4764,9.40591-4.52478,11.733-8.97372,1.62853-3.11346.13151-6.87689-1.36489-10.056l-4.57034-9.70963c-1.5167-3.22219-7.66515-1.36915-10.87048-2.14129C309.40271,753.74011,305.08147,752.63061,302.07409,756.73158Z"
            transform="translate(-88.18863 -52.49966)" fill="#2f2e41"/>


        <path d="M268.68863,445.00034l42,65s26,33,34,18-28-40-28-40l-27-50Z" transform="translate(-88.18863 -52.49966)"
              fill="#a0616a"/>
        <path
            d="M289.68863,323.00034l-8-2s-16,5-20,26-1,105,4,106,24.69494-14.6261,27.84747-11.313-6.84747-13.687-3.84747-22.687S289.68863,323.00034,289.68863,323.00034Z"
            transform="translate(-88.18863 -52.49966)" fill="#575a89"/>


    </svg>
)

export default PricingSvg;
